<template>
  <div id="adminPanel">
    <Sidebar></Sidebar>
  </div>
</template>

<script>
import Sidebar from '@/components/NavbarAndFooter/Sidebar.vue'

export default {
  components: { Sidebar }
}
</script>